import qs from 'qs'
import { isArray, isString } from '../is'

/**
 * 获url中的请求参数
 * @param url string
 * @returns Record<string, string | string[]> | TypeError
 */
export function queryString(url: string): Record<string, string | string[]> {
  if (!isString(url))
    throw new TypeError('Function queryString requires a string as an argument')
  if (!url.includes('?'))
    return {}
  const params = url.split('?').reverse()[0]
  const param = params.split('&')
  const query: Record<string, string | string[]> = {}

  for (const item of param) {
    const itemKeyValue = item.split('=')
    const key = itemKeyValue[0]
    const value = itemKeyValue[1] || ''
    if (Object.prototype.hasOwnProperty.call(query, key))
      query[key] = isArray(query[key]) ? [...query[key], value] : [query[key] as string, value]
    else
      query[key] = value
  }
  return query
}

/**
 * 将对象格式成url参数
 * @param query Record<string, any>
 * @param options qs options, see: https://www.npmjs.com/package/qs
 * @returns string
 */
export function queryUrl(query: Record<string, any>, options?: qs.IStringifyOptions) {
  return qs.stringify(query, { skipNulls: true, ...options })
}

// 清除字符串的空格和/n/t
export function trimStr(data: string) {
  if (data && isString(data))
    return data.trim().replace(/(\/n|\/t|\/r|\n|\t|\r)/g, '').trim()
  return data
}

// 判断两个路由是否是父子关系
// path: /a -> true: /a | /a/b | /a/    false: /ab
export function checkRouterPathRelative(parentPath: string, childPath: string) {
  return parentPath === childPath || !!childPath.match(new RegExp(`${parentPath}\/`))
}

export function addHttp(data: string, pre = 'http') {
  if (!isString(data)) {
    // console.warn('addHttp param is not string:', data)
    return data
  }
  if (!data)
    return '/'

  return data.match(/^http/) ? data : `${pre}://${data}`
}

/**
 * 获取字符串中的图片
 * @param str
 * @returns string[]
 */
export function getStrImgs(str: string): string[] {
  if (!str)
    return []
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
  let imgList: string[] = getStrImgTags(str)
  imgList = imgList?.map((v) => {
    const src = v?.match(srcReg) ? v?.match(srcReg)?.[1] : ''
    return src?.match(/^data:.*;base64.*/)
      ? src
      : (src || '')
  })

  return imgList.filter(v => v) ?? []
}

export function getStrImgTags(str: string): string[] {
  if (!str)
    return []
  const imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
  return str?.match(imgReg) ?? []
}
