/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isString } from '../is'

// 替换未转码的转义字符
export function transferStr(str: string) {
  if (!str)
    return str
  const regexp = /&(.*?);/g
  const obj = {
    lt: '<',
    gt: '>',
    amp: '&',
    quot: '"',
    apos: '\'',
    semi: ';',
    nbsp: '\xA0',
    ensp: '\u2002',
    emsp: '\u2003',
    ndash: '–',
    mdash: '—',
    middot: '·',
    lsquo: '‘',
    rsquo: '’',
    ldquo: '“',
    rdquo: '”',
    bull: '•',
    hellip: '…',
  } as const
  const arr = Array.from(new Set(str.match(regexp)))
  arr.forEach((v) => {
    v.match(regexp)
    const i = RegExp.$1
    // @ts-expect-error
    if (obj[i])
    // @ts-expect-error
      str = str.replace(new RegExp(v, 'g'), obj[i])
  })
  str = str.replace(regexp, '')
  return str
}
/**
 * 移除字符串中的标签
 * @param str string
 * @returns string
 */
export function removeElementTags(str: string) {
  if (!str || !isString(str))
    return ''
  str = transferStr(str)
  // const reg_1 = /\s*|\t|\r|\n/g
  const reg_1 = /\t|\r|\n/g
  const reg_2 = /<[^>]+>/g
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
  return str.replace(reg_1, '').replace(reg_2, '').replace(srcReg, '')
}

/**
 * @param colorValue 颜色值
 */
export function transferTextColor(colorValue: string) {
  // #123456或者rgb(12,34,56)转为rgb数组[12,34,56]
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  const that = colorValue
  if (/^(rgb|RGB)/.test(that)) {
    // 处理rgb转为数组
    const aColor = that.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',')
    return aColor
  }
  else if (reg.test(that)) {
    // 处理十六进制色值
    let sColor = colorValue.toLowerCase()
    if (!sColor || !reg.test(sColor))
      return colorValue

    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1)
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))

      sColor = sColorNew
    }
    // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2)
      sColorChange.push(parseInt(`0x${sColor.slice(i, i + 2)}`))

    return sColorChange
  }

  return colorValue
}
